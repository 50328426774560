import styled from "styled-components/macro";

type textProps = {
  color?: string,
  size?: string,
  weight?: string,
  padding?: string;
  margin?: string;
  align?: string;
  width?: string;
  opacity?: string;
  bgColor?: string;
  cursor?: string;
  textTransform?: string;
  textDecoration?: string;
  position?: string;
  bottom?: string;
  smsize?: string;
  hideSmall?: boolean;
  textOverflow?: string;
  overflow?: string
  whiteSpace?: string
  alignItem?: string
}

export const TextStyle = styled.div<textProps>`
display:${({ textOverflow }) => textOverflow === "ellipsis" ? "inline-block" : "flex"};
align-items:${({ alignItem }) => alignItem};
color:${({ color }) => color};
font-size:${({ size }) => size};
font-weight:${({ weight }) => weight};
padding:${({ padding }) => padding};
margin:${({ margin }) => margin};
text-align:${({ align }) => align};
justify-content:${({ align }) => align};
max-width: ${({ width }) => width};
opacity: ${({ opacity }) => opacity};
position: ${({ position }) => position};
bottom: ${({ bottom }) => bottom};
background:${({ bgColor }) => bgColor};
text-transform:${({ textTransform }) => textTransform};
text-decoration:${({ textDecoration }) => textDecoration};
white-space: ${({ whiteSpace }) => whiteSpace};
  overflow: ${({ overflow }) => overflow};
  text-overflow:${({ textOverflow }) => textOverflow};
border-radius: 5px;
cursor:${({ cursor }) => cursor};

@media(max-width:480px){
    font-size:${({ size, smsize }) => smsize || size};
    display:${({ hideSmall }) => hideSmall && "none"};
}

`
